import React, { useReducer, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { ButtonSubmit } from "components/buttons/ButtonSubmit";
import { ButtonRemove, ButtonCancel } from "components/buttons/ButtonsForm";
import CheckboxList from "components/lists/CheckboxList";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckboxField from 'components/fields/Checkbox.field';
import InputMask from 'react-input-mask';
import { alertError, alertSucccess } from 'utils/alerts';

import CommandPv2GprsFields, {
  getDefaultEnabled,
} from "components/commands/CommandPv2GprsFields";
import ModalFenceAddEquipment from "components/modals/ModalFenceAddEquipment";
import TableMenu, { TableColumn } from "components/table/TableMenu";
import CheckboxColumn from "components/table/columns/CheckboxColumn";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "assets/icon/lixeira.svg";
import ReactSVG from "react-svg";
import { useEffectSkipFirst } from "hooks/common";

import SimpleTable from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import TableHeaderAction from "components/table/TableHeaderAction";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import CircularLoading from "components/loading/CircularLoading";
import MapFence from "components/map/MapFence";
import AutoCompleteGray from "components/fields/AutoCompleteGray";
import { MyErrorMessage } from "components/fields/ErrorMessage";
import { emitEvent } from "utils/events";
import { logAction } from "utils/logs";

import {
  fetchAuthenticated,
  postWithErrorHandler,
  putWithErrorHandler,
} from "services/fetch";
import { useAccessLog } from "hooks/logs";

import Permission from "components/permissions/Permission";
import { validatePermission } from "services/permissions";

import { Formik } from "formik";
import * as Yup from "yup";

import TabPanel from "components/tabs/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
} from "utils/formatters";
import { buildUrl, defaultQuery } from "utils/query";
import { layerIsValid } from "utils/helpers";
import { Timer } from "@material-ui/icons";

function LeftCard(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      height: (props) => props.height,
      boxShadow: "none",
      marginRight: 25,
      paddingLeft: 29,
      paddingRight: 29,
      paddingTop: 32,
      paddingBottom: 32,
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    container: {
      ...theme.custom.containers.center,
      flexDirection: "column",
      alignItems: 'center'
    },
    fullWidthField: {
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles({
    height: 380,
  });

  const { showError, errorMsg, onNameBlur, fence, onDescriptionBlur } = props;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName((fence && fence.name) || "");
    setDescription((fence && fence.description) || "");
  }, [fence]);

  return (
    <Paper className={classes.root}>
      <Typography className={classes.header}>Informações</Typography>
      <div className={classes.container}>
        <TextField
          required
          label="Nome"
          name="name"
          className={classes.textField}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={(e) => {
            onNameBlur(e.target.value);
          }}
          fullWidth
          error={showError}
          helperText={showError && errorMsg}
        />
        <TextField
          label="Descrição"
          name="description"
          className={classes.textField}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onBlur={(e) => {
            onDescriptionBlur(e.target.value);
          }}
          fullWidth
        />
      </div>
    </Paper>
  );
}

function EditEquipments(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: (props) => props.minHeight,
      boxShadow: "none",
      marginTop: 23,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    removeIcon: {
      width: 47,
    },
    iconContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  }));
  const classes = useStyles({});

  const { equipments, setEquipments, fence, setFence } = props;
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [data, setData] = useState();
  const [equipmentsListToDelete, setEquipmentsListToDelete] = useState({});

  useEffect(() => {
    if (fence && Object.keys(fence).length > 0) {
      setData({ data: fence.equipments });

      let equipmentsObject = {}

      for (let equipment of fence.equipments) {
        equipmentsObject[equipment.id] = false
      }

      setEquipmentsListToDelete(equipmentsObject)
    }

    // setTimeout(() => {setTest({test: true})}, 10000)

  }, [fence]);

  const onRemoveClick = async (id) => {
    setIsRemoving(true);
    const _equipments = fence.equipments.filter((equip) => equip._id !== id);
    const logMessage = "Remover equipamento em editar cercas.";
    const successMessage = "Equipamentos atualizados.";
    const errorMessage = "Erro ao tentar atualizar os equipamentos.";
    try {
      const response = await putWithErrorHandler(
        `fence/${fence._id}`,
        {
          equipments: _equipments.map((equip) => equip._id),
        },
        { logMessage, successMessage, errorMessage }
      );
      setFence(response);
      setIsRemoving(false);
    } catch (e) {
      setIsRemoving(false);
    }
  };

  const onRemoveAllClick = async () => {
    setIsRemoving(true)

    const _equipments_to_delete = Object.keys(equipmentsListToDelete).filter((item, index) => Object.values(equipmentsListToDelete)[index])

    const _equipments = fence.equipments.filter(equip => !_equipments_to_delete.includes(equip._id))

    const logMessage = "Remover equipamento em editar cercas.";
    const successMessage = "Equipamentos atualizados.";
    const errorMessage = "Erro ao tentar atualizar os equipamentos.";
    try {
      const response = await putWithErrorHandler(
        `fence/${fence._id}`,
        {
          equipments: _equipments.map((equip) => equip._id),
        },
        { logMessage, successMessage, errorMessage }
      );
      setFence(response);
      setIsRemoving(false);
    } catch (e) {
      setIsRemoving(false);
    }

    // setTimeout(() => {setIsRemoving(false)}, 3000)
  }

  const onChangeFenceCheckbox = async (event, rowData) => {

    if (event) {
      let newObject = JSON.parse(JSON.stringify(equipmentsListToDelete))
      newObject[rowData.id] = true
      setEquipmentsListToDelete(newObject)

    } else {
      let newObject = JSON.parse(JSON.stringify(equipmentsListToDelete))
      newObject[rowData.id] = false
      setEquipmentsListToDelete(newObject)

    }
  }

  const onAllCheckboxChange = async (event) => {

    if (event) {

      let equipmentsList = JSON.parse(JSON.stringify(equipmentsListToDelete))

      for (let equipment of equipments) {
        equipmentsList[equipment.id] = true
      }

      setEquipmentsListToDelete(equipmentsList)
    } else {

      let equipmentsList = JSON.parse(JSON.stringify(equipmentsListToDelete))

      for (let equipment of equipments) {
        equipmentsList[equipment.id] = false
      }

      setEquipmentsListToDelete(equipmentsList)
    }
  }
  // onChange={ (e) => {
  //   handleCheckboxOnChange(e, item);
  // }}

  const columns = [
    {
      label: () => (
        <CheckboxColumn onChange={(e) => onAllCheckboxChange(e)} />
      ),
      component: (row) => (
        <CheckboxColumn checked={equipmentsListToDelete[row.id]} onChange={(e) => onChangeFenceCheckbox(e, row)} />
      ),
    },
    { label: "Equipamento", value: (row) => row.vehicle || row.shortImei },
    {
      label: "Empresa",
      value: (row) => row.currentCompany && row.currentCompany.name,
    },
    {
      component: (row) => (
        <TableColumn key={row._id} row={row}>
          <div className={classes.iconContainer}>
            <IconButton
              style={{ boxShadow: "unset" }}
              title="Remover"
              className={classes.removeIcon}
              onClick={() => onRemoveClick(row._id)}
            >
              <ReactSVG src={RemoveIcon} />
            </IconButton>
          </div>
        </TableColumn>
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <CircularLoading isLoading={isRemoving} />
        <ModalFenceAddEquipment
          isVisible={isAddVisible}
          setIsVisible={setIsAddVisible}
          fence={fence}
          setFence={setFence}
          equipments={equipments}
          setEquipments={setEquipments}
        />
        <SimpleTable
          columns={columns}
          showPagination={false}
          data={data}
          containerStyle={{
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 23,
            marginBottom: 0,
            paddingBottom: 0,
          }}
          paperStyle={{
            paddingTop: 0,
          }}
          HeaderComponent={
            <TableHeader
              headerFilters={[
                <Typography className={classes.header} key="headerFilter">
                  Equipamentos
                </Typography>,
              ]}
              headerActions={
                Object.values(equipmentsListToDelete).includes(true) ?
                  [
                    <TableHeaderAction
                      title="Adicionar equipamento"
                      Icon={Add}
                      onClick={() => {
                        setIsAddVisible(true);
                      }}
                    />,
                    <TableHeaderAction
                      title="Excluir equipamentos"
                      Icon={Delete}
                      onClick={() => {
                        onRemoveAllClick();
                      }}
                    />,
                  ] : [
                    <TableHeaderAction
                      title="Adicionar equipamento"
                      Icon={Add}
                      onClick={() => {
                        setIsAddVisible(true);
                      }}
                    />
                  ]}
            />
          }
        />
      </Grid>
    </Grid>
  );
}

export function TimeRange(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: (props) => props.minHeight,
      boxShadow: "none",
      marginTop: 23,
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: 29,
      marginBottom: 27,
    },
    container: {
      marginTop: 17,
      borderBottom: "solid 1px #c7cad94d",
      paddingBottom: 26,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    chip: {
      marginRight: 10,
      // marginTop: 10,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
  }));

  const classes = useStyles({
    minHeight: 240,
  });

  const {
    weekDays,
    setWeekDays,
    rangeEnabled,
    setRangeEnabled,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    isValidStartTime,
    setIsValidStartTime,
    isValidEndTime,
    setIsValidEndTime,
    containerStyle,
  } = props;

  const handleCheckboxChange = (event) => {
    setRangeEnabled(event.target.checked);
  };

  // Função para atualizar o estado quando um checkbox for alterado
  const handleWeekDayCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setWeekDays(prevDays => ({
      ...prevDays,
      [name]: checked,
    }));
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    setIsValidStartTime(validateTime(event.target.value))
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setIsValidEndTime(validateTime(event.target.value))
  };

  const validateTime = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  const weekDaysCheckbox = [
    { name: 'sun', label: 'Dom' },
    { name: 'mon', label: 'Seg' },
    { name: 'tue', label: 'Ter' },
    { name: 'wed', label: 'Qua' },
    { name: 'thu', label: 'Qui' },
    { name: 'fri', label: 'Sex' },
    { name: 'sat', label: 'Sab' },
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Paper className={classes.root} style={containerStyle}>
          <Typography className={classes.header}>Range de horário</Typography>
          <Grid container spacing={1}>
            <Grid item className={classes.container}>
              <Checkbox
                className={classes.checkbox}
                onChange={handleCheckboxChange}
                checked={rangeEnabled}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
                checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
              />
            </Grid>
            <Grid item className={classes.container}>
              <InputMask
                mask="99:99:99"
                value={startTime}
                onChange={(e) => handleStartTimeChange(e)}
                disabled={!rangeEnabled}
              >

                {() => (
                  <TextField
                    required
                    // ref={fields.name}
                    fullWidth
                    className={classes.fullWidthField}
                    label="Horário início"
                    name="start_time"
                    helperText={!isValidStartTime ? 'Horário inválido' : ''}
                    disabled={!rangeEnabled}
                  />
                )}

              </InputMask>
            </Grid>
            <Grid item className={classes.container}>
              <InputMask
                mask="99:99:99"
                value={endTime}
                onChange={(e) => handleEndTimeChange(e)}
                disabled={!rangeEnabled}
              >
                {() => (
                  <TextField
                    required
                    fullWidth
                    className={classes.fullWidthField}
                    label="Horário fim"
                    name="end_time"
                    helperText={!isValidEndTime ? 'Horário inválido' : ''}
                    disabled={!rangeEnabled}
                  />
                )}
              </InputMask>
            </Grid>
            {
              weekDaysCheckbox.map(data => (
                <Grid item className={classes.container}>
                  <Checkbox
                    className={classes.checkbox}
                    onChange={handleWeekDayCheckboxChange}
                    checked={weekDays !== undefined ? weekDays[data.name] : false}
                    name={data.name}
                    color="primary"
                    disabled={!rangeEnabled}
                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
                    checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
                  />
                  <label>{data.label}</label>
                </Grid>
              ))
            }

          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
TimeRange.defaultProps = {
  containerStyle: {},
  hideTitle: false,
  checkAll: false,
};

export function SelectEquipments(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: (props) => props.minHeight,
      boxShadow: "none",
      marginTop: 23,
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: 29,
      marginBottom: 27,
    },
    container: {
      marginTop: 17,
      borderBottom: "solid 1px #c7cad94d",
      paddingBottom: 26,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    chip: {
      marginRight: 10,
      // marginTop: 10,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
  }));

  const classes = useStyles({
    minHeight: 240,
  });

  const {
    equipments,
    setEquipments,
    fence,
    containerStyle,
    hideTitle,
    checkAll,
  } = props;
  const [loadingEquipments, setLoadingEquipments] = useState(false);
  const [chips, setChips] = useState([]);

  const loadEquipments = async (company) => {
    if (!company) {
      setEquipments([]);
    } else {
      setLoadingEquipments(true);
      const filter = {
        "currentCompany._id": {
          value: company._id,
          toObjectId: false,
        },
      };
      const _equipments = await fetchAuthenticated(
        "get",
        `equipment/list?filter=${JSON.stringify(filter)}`
      );
      const data = await _equipments.json();
      const _equipmentsToAdd = [];
      data.forEach((equip) => {
        if (!equipments.some((_equip) => _equip.imei === equip.imei)) {
          _equipmentsToAdd.push(equip);
        }
      });
      setEquipments([...equipments, ..._equipmentsToAdd]);
      setLoadingEquipments(false);
    }
  };

  const handleDelete = (chip) => {
    const _chips = chips.filter((item) => item._id !== chip._id);
    const _equipments = equipments.filter(
      (equipment) => equipment.currentCompany._id !== chip._id
    );
    setChips(_chips);
    setEquipments(_equipments);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Paper className={classes.root} style={containerStyle}>
          {loadingEquipments && (
            <CircularLoading isLoading={loadingEquipments} />
          )}
          {!hideTitle && (
            <Typography className={classes.header}>Equipamentos</Typography>
          )}
          <Grid container>
            <Grid item xs={12} className={classes.container}>
              <AutoCompleteGray
                menuContainerStyle={{
                  marginTop: 5,
                }}
                containerStyle={{
                  width: 254,
                  marginRight: 10,
                }}
                fetchUrl="company?notPaginated=true&fields=_id,name"
                placeholder="Buscar empresa"
                showPlaceholder={true}
                hideLabel={true}
                name="company"
                getOptionLabel={(opt) => opt.name}
                loadingMessage="Carregando empresas..."
                noOptionsMessage="Nenhuma empresa encontrada."
                onChange={({ opt }) => {
                  if (opt && !chips.some((chip) => chip._id === opt._id)) {
                    loadEquipments(opt);
                    setChips([...chips, { name: opt.name, _id: opt._id }]);
                  }
                }}
              />
              {chips.map((item, index) => (
                <Fade key={item._id + index} in={true} timout={1000}>
                  <Chip
                    label={item.name}
                    clickable
                    color="primary"
                    onDelete={() => {
                      handleDelete(item);
                    }}
                    deleteIcon={<CloseIcon style={{ width: 15 }} />}
                    variant="outlined"
                    className={classes.chip}
                  />
                </Fade>
              ))}
            </Grid>
            <Grid item xs={12} className={classes.checkboxContainer}>
              {equipments.length === 0 && (
                <Typography className={classes.placeholder}>
                  Nenhum equipamento encontrado.
                </Typography>
              )}
              <CheckboxList
                data={equipments}
                setData={setEquipments}
                getName={(item) => item.shortImei}
                labelStyle={{
                  fontSize: 16,
                  color: "#41475a",
                  fontWeight: "normal",
                }}
                checkAll={checkAll}
                containerStyle={{
                  flexDirection: "row",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                showCheckAll={equipments.length > 0}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
SelectEquipments.defaultProps = {
  containerStyle: {},
  hideTitle: false,
  checkAll: false,
};

function Commands(props) {
  // const { getCommandData, setCommandData, getExitCommandData, setExitCommandData, fence } = props;
  const { setCommandData, setExitCommandData, fence } = props;

  const [command, setCommand] = useState({});
  const [exitCommand, setExitCommand] = useState({});

  const [defaultEnabledCommands, setDefaultEnabledCommands] = useState(null);
  const [defaultEnabledExitCommands, setDefaultEnabledExitCommands] = useState(
    null
  );

  const [currentTab, setCurrentTab] = useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: (props) => props.minHeight,
      boxShadow: "none",
      marginTop: 23,
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
      marginBottom: 27,
    },
  }));

  const classes = useStyles({});

  /* set enabled commands */
  useEffectSkipFirst(() => {
    if (fence) {
      if (fence.command) {
        const enabledCommandFields = getDefaultEnabled(false, fence.command);
        setDefaultEnabledCommands(enabledCommandFields);
        setCommand(fence.command);
      }
      if (fence.exitCommand) {
        const enabledCommandFields = getDefaultEnabled(
          false,
          fence.exitCommand
        );
        setDefaultEnabledExitCommands(enabledCommandFields);
        setExitCommand(fence.exitCommand);
      }
    }
  }, [fence]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Paper className={classes.root}>
          <Tabs
            value={currentTab}
            onChange={(e, index) => setCurrentTab(index)}
            indicatorColor="primary"
          >
            <Tab label="Entrada" />
            <Tab label="Saída" />
          </Tabs>
          <TabPanel index={0} value={currentTab}>
            <CommandPv2GprsFields
              setData={setCommandData}
              defaultEnabledCommands={defaultEnabledCommands}
              canDisable
              disableSubmit
              initialValues={command}
            />
          </TabPanel>
          <TabPanel index={1} value={currentTab}>
            <CommandPv2GprsFields
              setData={setExitCommandData}
              defaultEnabledCommands={defaultEnabledExitCommands}
              canDisable
              disableSubmit
              initialValues={exitCommand}
            />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
}

function FenceForm(props) {
  useAccessLog("Acesso a cerca");
  const { classes, location, fence, setFence, title, history } = props;
  const [layer, setLayer] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [command, setCommand] = useState((fence && fence.command) || {});
  const [exitCommand, setExitCommand] = useState(
    (fence && fence.exitCommand) || {}
  ); // aqui
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [showNameRequired, setShowNameRequired] = useState(false);
  const [showLayerRequired, setShowLayerRequired] = useState(false);

  const [rangeEnabled, setRangeEnabled] = useState(false);
  const [weekDays, setWeekDays] = useState({
    sun: true,
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
  });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isValidStartTime, setIsValidStartTime] = useState(true);
  const [isValidEndTime, setIsValidEndTime] = useState(true);

  /* data to be send */
  const [data, setData] = useState({});

  useEffect(() => {
    if (fence && Object.keys(fence).length > 0) {
      setLayer(fence.layer);
      setName(fence.name);
      setDescription(fence.description);
      setEquipments(fence.equipments);
      setRangeEnabled(fence.rangeEnabled);
      setStartTime(fence.startTime);
      setEndTime(fence.endTime);
      setWeekDays(fence.weekDays);
    }
  }, [fence]);

  const _onSubmit = (values) => {
    setData({ ...values });
    setSubmit(true);
  };

  const buildDataAndSubmit = async (
    data,
    command,
    equipments,
    layer,
    exitCommand
  ) => {
    const onlyCheckedEquipments = equipments.filter((equip) => equip.checked);
    let __layer = {}
    let geoJSON = null;
    if (layerIsValid(layer)) {
      __layer = {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      }
      geoJSON = layer.layer.toGeoJSON()
    }
    const _data = {
      ...data,
      command,
      exitCommand,
      equipments: onlyCheckedEquipments.map((equip) => equip._id),
      layer: __layer,
      geoJSON: geoJSON,
    };

    const successMessage = "Cerca criada.";
    const errorMessage = "Erro ao tentar criar uma cerca.";
    const logMessage = "Criação de cercas.";
    try {
      const response = await postWithErrorHandler("fence", _data, {
        successMessage,
        errorMessage,
        logMessage,
      });
      // history.push(`/cercas/${response._id}`);
      history.push(`/cercas`);
    } catch (e) {
      console.log(e);
    }
  };

  const editSubmit = async (
    data,
    command,
    equipments,
    layer,
    fence,
    exitCommand
  ) => {
    const onlyCheckedEquipments = equipments.filter((equip) => equip.checked);
    const _data = {
      ...data,
      command,
      exitCommand,
    };

    if (layer.needParse) {
      _data.layer = {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      };
      _data.geoJSON = layer.layer.toGeoJSON();
    }

    const successMessage = "Informações atualizadas.";
    const errorMessage = "Erro ao tentar atualizar a cerca.";
    const logMessage = "Edição de cercas.";
    const response = await putWithErrorHandler(`fence/${fence._id}`, _data, {
      successMessage,
      errorMessage,
      logMessage,
    });

    history.push(`/cercas`);
  };

  /* Listen for user submit, and then ask for command data by
   * setting setCommand(true). After that, when command is set,
   * we are ready to submit.
   */
  useEffect(() => {
    if (submit) {
      const isCommandSet = command && Object.keys(command).length > 0;
      /* command is set and new fence */
      if (isCommandSet && !fence) {
        buildDataAndSubmit(data, command, equipments, layer, exitCommand);
        setSubmit(false);
        return;
      }

      /* command is set and edit fence */
      if (isCommandSet && fence) {
        editSubmit(data, command, equipments, layer, fence, exitCommand);
        setSubmit(false);
        return;
      }

      /* if user pressed submit, lets get command data */
      if (command !== null) {
        setCommand(null);
        setExitCommand(null);
      }
    }
    // equipments and layer are here so their values are
    // updated when buildDataAndSubmit is fired
  }, [submit, data, command, exitCommand, equipments, layer]);

  const validateFields = () => {
    setShowNameRequired(!name);
    if (!rangeEnabled) {
      setShowLayerRequired(!Object.keys(layer).length > 0);
    }

    if (name) {
      if (rangeEnabled) {
        if (isValidStartTime && isValidEndTime) {
          _onSubmit({ name, layer, description, rangeEnabled, startTime, endTime, weekDays });
        }
      } else if (layerIsValid(layer)) {
        _onSubmit({ name, layer, description, rangeEnabled, startTime, endTime, weekDays });
      } else {
        alertError('A seleção de forma geométrica ou de intervalo de tempo é obrigatória!');
        return;
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
      </div>
      <Grid container className={classes.mapGrid}>
        <Grid item xs={3}>
          <LeftCard
            onNameBlur={setName}
            onDescriptionBlur={setDescription}
            showError={showNameRequired}
            errorMsg="Campo obrigatório"
            fence={fence}
          />
        </Grid>
        <Grid item xs={9}>
          <MyErrorMessage isVisible={showLayerRequired}>
            Forma geométrica obrigatória
          </MyErrorMessage>
          <MapFence
            fence={fence}
            height={380}
            containerStyle={{}}
            equipments={[]}
            onLayerChange={(layer) => {
              setLayer(layer);
            }}
          />
        </Grid>
      </Grid>
      <TimeRange
        weekDays={weekDays}
        setWeekDays={setWeekDays}
        rangeEnabled={rangeEnabled}
        setRangeEnabled={setRangeEnabled}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        isValidStartTime={isValidStartTime}
        setIsValidStartTime={setIsValidStartTime}
        isValidEndTime={isValidEndTime}
        setIsValidEndTime={setIsValidEndTime}
      />
      {!fence && (
        <SelectEquipments
          fence={fence}
          equipments={equipments}
          setEquipments={setEquipments}
        />
      )}
      {fence && (
        <EditEquipments
          fence={fence}
          setFence={setFence}
          equipments={equipments}
          setEquipments={setEquipments}
        />
      )}
      <Commands
        // getCommandData={command}
        // getExitCommandData={exitCommand}
        setCommandData={setCommand}
        setExitCommandData={setExitCommand}
        fence={fence}
      />
      <Grid
        container
        justify="flex-end"
        spacing={1}
        style={{ marginTop: 20, marginBottom: 28 }}
      >
        {props.handleCancel && (
          <Grid item xs={12} md="auto">
            <ButtonCancel onClick={props.handleCancel} />
          </Grid>
        )}
        {props.handleRemove && (
          <Grid item xs={12} md="auto">
            <ButtonRemove onClick={props.handleRemove} />
          </Grid>
        )}
        <Grid item xs={12} md="auto">
          <ButtonSubmit
            // disabledCondition={isSubmitting}
            onSubmit={() => {
              validateFields(true);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: theme.main.padding.top,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  leftCardPaper: {
    borderRadius: 16,
  },
  mapGrid: {
    paddingTop: theme.main.padding.top,
  },
});
export default withRouter(withStyles(styles)(FenceForm));
